import React from 'react'
import Button from './Button'
import { FiArrowRight, AiOutlineDollarCircle, BiLineChartDown,BsCalendarWeek } from 'react-icons/all'
import style from '../style/components/PromotionImovel.module.css'
import { Link } from 'react-router-dom'

export function PromotionImovel() {
    return (
        <div>
            <Link to={'/imovel'}>
                <Button
                    style={{
                        fontWeight: 500,
                        width: '320px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    type='quaternary'
                    contained
                    bigger
                    fillWhenMobile>
                    Simular agora

                    <FiArrowRight style={{ fontSize: '1.6rem' }} />
                </Button>
            </Link>
            <ul className={style.checkItemContainer}>
                <li>
                    <AiOutlineDollarCircle />
                    <p>Empréstimo de R$ 50 mil a R$ 15 milhões - Consiga até 60% do valor do seu imóvel.</p>
                </li>
                <li>
                    <BiLineChartDown />
                    <p>Taxas a partir de 0,90% a.m, opções pós e pré-fixadas.</p>
                </li>
                <li>
                    <BsCalendarWeek />
                    <p>Até 240 meses para pagar.</p>
                </li>
            </ul>
        </div>
    )
}

export default PromotionImovel