import React from 'react'
import style  from '../style/components/navbutton.module.css'
import { Link } from "react-router-dom";

function NavButton(props) {
    const { link, callback, text, className:propClass } = props;   

    if(callback){
        return (
            <div className={`${style.link} ${propClass}`} onClick= {callback}>
                {text}
            </div>
        )
    }else{
        return (
            <Link className={`${style.link} ${propClass}`} to={`/${link}`}>
                <div>
                    {text}
                </div>
            </Link>
        )
    }   
}

export default NavButton
