import React, { Component } from 'react'
import style from '../style/components/hamburgermenu.module.css'

export class HamburgerMenu extends Component {
    state = {
        noBurger: false
    }

    toogleBurguer = () => {
        if(this.props.callback)
            this.props.callback();

        this.setState({
            noBurger: !this.state.noBurger
        })
    }

    render() {
        let hmClass = '';

        if(this.state.noBurger)
            hmClass = style["no-hamburgler"]
        else
            hmClass = '';

        
        return (
            <div onClick={this.toogleBurguer} className={`${style.hamburgler} ${hmClass}`}>
                <div className={`${style.bun} ${style.top}`}/>
                <div className={`${style.meat}`}></div>
                <div className={`${style.bun} ${style.bottom}`}/>
            </div>
        )
    }
}

export default HamburgerMenu
