import React from "react";
import style from "../style/components/iconbanner.module.css";
import {
    FaPercentage,
    FaLessThan,
    GiMoneyStack,
    BsCalendar3,
    GiReceiveMoney,
    FaCreditCard,
    GiFamilyHouse,
    HiUserGroup
} from "react-icons/all";

export function IconBanner(props) {
    const {
        style: prevStyle
    } = props;

    return (
        <div className={style.wrapper} style={prevStyle}>
            <div className={style.container}>
                <h1 className={style.title}>
                    <span>Vantagens</span>{' '}do Home Equity
                </h1>
                <h2 className={style.subtitle}>
                    Crédito com garantia de imóvel
                </h2>
                <div className={style.containerIcons}>
                    <div className={style.gridIcons}>
                        <div className={style.icon}>
                            <GiReceiveMoney />
                        </div>
                        <div className={style.description}>
                            O imóvel não precisa estar quitado e pode estar em nome
                            de terceiros, em nome de Pessoas Físicas ou Jurídicas.
                            Aceitamos casas, apartamentos, salas comerciais com ou
                            sem habite-se e terrenos em área urbana.
                        </div>
                    </div>
                    
                    <div className={style.gridIcons}>
                        <div className={style.icon}>
                            <GiMoneyStack />
                        </div>
                        <div className={style.description}>
                            Empréstimos a partir de R$ 50.000,00
                        </div>
                    </div>

                    <div className={style.gridIcons}>
                        <div className={style.icon}>
                            <FaPercentage />
                        </div>
                        <div className={style.description}>
                            Maior valor de liberação para usar como quiser:
                            o Banco libera até 60% do valor do imóvel
                        </div>
                    </div>

                    <div className={style.gridIcons}>
                        <div className={style.icon}>
                            <BsCalendar3 />
                        </div>
                        <div className={style.description}>
                            Prazos  alongados: até 240 meses para pagar
                        </div>
                    </div>
                    <div className={style.gridIcons}>
                        <div className={style.icon}>
                            <FaLessThan />
                        </div>
                        <div className={style.description}>
                            A menor taxa do mercado: taxas a partir de 0,90% ao mês
                        </div>
                    </div>


                    <div className={style.gridIcons}>
                        <div className={style.icon}>
                            <FaCreditCard />
                        </div>
                        <div className={style.description}>
                            É possível aprovar mesmo com restrições de crédito, desde que não sejam muito relevantes em relação ao valor a ser financiado.
                        </div>
                    </div>

                    <div className={style.gridIcons}>
                        <div className={style.icon}>
                            <GiFamilyHouse />
                        </div>
                        <div className={style.description}>
                            O imóvel continua em seu nome, livre  para ser usado ou alugado
                        </div>
                    </div>
                    <div className={style.gridIcons}>
                        <div className={style.icon}>
                            <HiUserGroup />
                        </div>
                        <div className={style.description}>
                            Você poderá compor renda com outras pessoas para aprovação do crédito
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default IconBanner;