import { useState } from 'react';

export default function useSteps(actualStep){
    const [step, setStep] = useState(actualStep);
    
    const nextStep = (e) => setStep(step + 1);
    const prevStep = (e) => setStep(step - 1);

    const showStep = (elem) => {
        elem.style.display = ""
    }

    const hideStep = (elem) => {
        elem.style.display = "none"
    }

    return {
        step,
        nextStep,
        prevStep,
        showStep,
        hideStep,
    }
}