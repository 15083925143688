import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import style from '../style/components/stepform.module.css'
import Button from './Button'
import { sendFormData, limpaCampo, TestaCPF } from '../util/util'
import useSteps from '../hooks/step-hook'
import axios from 'axios'

import {
    CurrencyFormat,
    ValorDesejadoFormat,
    CpfMaskedInput,
    CepMaskedInput,
    DateMaskedInput,
    CellMaskedInput
} from "./Masks";

function ImovelForm(props) {
    const step1 = useForm();
    const step2 = useForm();
    const step3 = useForm();

    const qtdSteps = 3;
    const {
        step,
        nextStep,
        prevStep,
        showStep,
        hideStep,
    } = useSteps(1);
    const [formId, setId] = useState(0);

    useEffect(() => {
        let actualStep = document.querySelector("#step_" + step)

        for (let i = 1; i <= qtdSteps; ++i) {
            hideStep(document.querySelector("#step_" + i))
        }

        showStep(actualStep);
    });

    const onSubmitStep1 = function (data) {
        nextStep()
    }

    const onSubmitStep2 = function (data) {
        data.ValorEmprestimo = step1.getValues().ValorEmprestimo / 100
        data.ValorImovel = step1.getValues().ValorImovel / 100

        nextStep();
        sendFormData(data, 'imovel').then(retorno => {
            setId(retorno)
            console.log(formId)

            if (retorno)
                nextStep();

        });
    }

    const onSubmitStep3 = function (data) {
        data.Renda = data.Renda / 100;
        data = { Id: formId, ...data }

        sendFormData(data, 'imovel', true)
            .then(response => {
                if (response) {
                    console.log(response)
                    window.location.href = "/resultado-cgi";
                }
            });
    }

    return (
        <div className={style.form}>
            <form
                onSubmit={step1.handleSubmit(onSubmitStep1)}
                id="step_1"
            >
                <h1>
                    Simule agora
                </h1>
                <div className={style.steps} >
                    <label for="ValorEmprestimo">De quanto você precisa?</label>
                    <Controller
                        name="ValorEmprestimo"
                        control={step1.control}
                        rules={{
                            required: 'Preencha esse campo para continuar.', validate: {
                                lessThanRequired: (value) => value >= 5000000
                            }
                        }}
                        as={ValorDesejadoFormat}
                    />
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step1.errors.ValorEmprestimo?.type === 'required' && step1.errors.ValorEmprestimo.message}
                        {step1.errors.ValorEmprestimo?.type === 'lessThanRequired' && "O valor deve ser igual ou maior que R$50.000,00"}
                    </p>


                    <label for="ValorImovel">Qual o valor do imóvel?</label>
                    <Controller
                        name="ValorImovel"
                        control={step1.control}
                        rules={{
                            required: 'Preencha esse campo para continuar.', validate: {
                                lessThanRequired: (value) => value >= 25000000
                            }
                        }}
                        as={CurrencyFormat}
                    />
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step1.errors.ValorImovel?.type === 'required' && step1.errors.ValorImovel.message}
                        {step1.errors.ValorImovel?.type === 'lessThanRequired' && "O valor deve ser igual ou maior que R$250.000,00"}
                    </p>


                    <Button className={style.btn} type="secondary" contained>Solicitar Simulação</Button>
                </div>
            </form>
            <form
                onSubmit={step2.handleSubmit(onSubmitStep2)}
                id="step_2"
            >
                <div className={style.steps} >
                    <label for="Nome">Nome</label>
                    <input ref={step2.register({ required: 'Preencha esse campo para continuar' })} name="Nome"></input>
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step2.errors.Nome?.type === 'required' && step2.errors.Nome.message}
                    </p>

                    <label for="Celular">Celular</label>
                    <Controller
                        name="Celular"
                        control={step2.control}
                        as={<CellMaskedInput />}
                        rules={{ required: 'Preencha esse campo para continuar' }}
                    />
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step2.errors.Celular?.type === 'required' && step2.errors.Celular.message}
                    </p>

                    <label for="Email">Email</label>
                    <input ref={step2.register({
                        required: 'Preencha esse campo para continuar',
                        pattern: /^[a-zA-Z0-9._-]+?@[a-zA-Z0-9_-]+?\.[a-zA-Z]{2,6}((\.[a-zA-Z]{2,4}){0,1})$/
                    })} name="Email" />
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step2.errors.Email?.type === 'required' && step2.errors.Email.message}
                        {step2.errors.Email?.type === 'pattern' && 'Insira um Email válido'}
                    </p>
                    <Button className={style.btn} type="secondary" contained>Confirmar</Button>
                    <Button className={style.btn} elem="div" type="primary" contained callback={prevStep}>Voltar</Button>
                </div>
            </form>
            <form
                onSubmit={step3.handleSubmit(onSubmitStep3)}
                id="step_3"
            >
                <div className={style.steps}>
                    <label for="Renda">Renda mensal familiar</label>
                    <Controller
                        name="Renda"
                        control={step3.control}
                        as={CurrencyFormat}
                        rules={{ required: 'Preencha esse campo para continuar' }}
                    />
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step3.errors.Renda?.type === 'required' && step3.errors.Renda.message}
                    </p>

                    <label for="CPF">CPF</label>
                    <Controller
                        name='CPF'
                        as={<CpfMaskedInput />}
                        control={step3.control}
                        rules={{
                            required: 'Preencha esse campo para continuar',
                            validate: {
                                valid: (value) => TestaCPF(limpaCampo(value))
                            }
                        }}
                    />
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step3.errors.CPF?.type === 'required' && step3.errors.CPF.message}
                        {step3.errors.CPF?.type === 'valid' && 'insira um CPF válido'}
                    </p>

                    <label for="TipoRenda">Tipo de Renda</label>
                    <select ref={step3.register} name="TipoRenda">
                        <option>Assalariado</option>
                        <option>Empresario</option>
                        <option>Aposentado</option>
                        <option>Funcionário Público</option>
                        <option>Autônomo</option>
                        <option>Profissional Liberal</option>
                    </select>

                    <label for="DataNascimento">Data Nascimento</label>
                    <Controller
                        name="DataNascimento"
                        control={step3.control}
                        as={<DateMaskedInput />}
                        rules={{
                            required: 'Preencha esse campo para continuar', validate: {
                                valid: (value) => {
                                    let dataAux = value.split('/')

                                    let year = parseInt(limpaCampo(dataAux.pop()));
                                    let month = parseInt(limpaCampo(dataAux.pop()));
                                    let day = parseInt(limpaCampo(dataAux.pop()));

                                    if (year < 1920 || year > new Date().getFullYear())
                                        return false;

                                    if (month < 1 || month > 12)
                                        return false;

                                    if (day > 31 || day < 1)
                                        return false;

                                    return true;
                                },
                                maioridade: (value) => {
                                    let dataAux = value.split('/')

                                    const year = parseInt(dataAux.pop());
                                    const month = parseInt(dataAux.pop());
                                    const day = parseInt(dataAux.pop());
                                    let date = new Date(year, month - 1, day);

                                    let dezoitoDate = new Date(
                                        new Date().getFullYear() - 18,
                                        new Date().getMonth(),
                                        new Date().getDay(),
                                    );

                                    if (date <= dezoitoDate) {
                                        return true;
                                    }

                                    return false;
                                }
                            }
                        }}
                    />
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step3.errors.DataNascimento?.type === 'required' && step3.errors.DataNascimento.message}
                        {step3.errors.DataNascimento?.type === 'valid' && 'Data inválida favor preencha no formato DD/MM/AAAA.'}
                        {step3.errors.DataNascimento?.type === 'maioridade' && 'Idade mínima é 18 Anos.'}
                    </p>

                    <label for="CEP">CEP</label>
                    <Controller
                        name="CEP"
                        control={step3.control}
                        as={<CepMaskedInput />}
                        rules={{
                            required: 'Preencha esse campo para continuar', validate: {
                                valid: async (value) => {
                                    try {
                                        const cep = limpaCampo(value)
                                        if (cep.length === 8) {
                                            const json = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
                                            return (!json.data.erro);
                                        }
                                    }
                                    catch {
                                        return false;
                                    }
                                }
                            }
                        }}
                    />
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step3.errors.CEP?.type === 'required' && step3.errors.CEP.message}
                        {step3.errors.CEP?.type === 'valid' && 'insira um CEP válido'}
                    </p>

                    <label for="Numero">Número</label>
                    <input ref={step3.register({ required: 'Preencha esse campo para continuar' })} name="Numero"></input>
                    <p style={{
                        color: "#fa2a83",
                        margin: "0 0 5px 0"
                    }}>
                        {step3.errors.Numero?.type === 'required' && 'Preencha esse campo para continuar'}
                    </p>
                    <p className={style.observation}>
                        Seus dados estão protegidos em sistema seguro e só serão utilizados para pré-analise de crédito.
                    </p>
                    <Button className={style.btn} type="tertiary" contained submit>Solicitar simulação</Button>
                </div>
            </form>
        </div>
    )
}

export default ImovelForm