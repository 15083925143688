import React, { useState } from "react";
import NumberFormat from "react-number-format";
import MaskedInput from "react-input-mask";
import axios from 'axios'
import { limpaCampo } from '../util/util'

export const Input = props => {
    const { onChange, ...restProps } = props;
    return <input {...restProps} onChange={onChange} />;
};

export const CurrencyFormat = ({ onChange, value, ...rest }) => {
    const [currency, setCurrency] = React.useState(value / 100);
    return (
      <NumberFormat
        {...rest}
        value={currency}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale
        onValueChange={target => {
            if(!target.floatValue) {
                setCurrency(undefined);
                onChange(undefined);
            } else{
                setCurrency(target.floatValue);
                onChange(target.floatValue * 100);
            }
        }}
        isNumericString
        prefix="R$ "
      />
    );
}

export const ValorDesejadoFormat = ({ onChange, value, ...rest }) => {
    const [currency, setCurrency] = React.useState(value / 100);

    return (
        <NumberFormat
            {...rest}
            value={currency}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            fixedDecimalScale
            onValueChange={target => {
                if(!target.floatValue) {
                    setCurrency(undefined);
                    onChange(undefined);
                } else{
                    setCurrency(target.floatValue);
                    onChange(target.floatValue * 100);
                }
            }}
            isNumericString
            prefix="R$ "
        />
    );
}

export const CpfMaskedInput = props => {
    const { value, onChange, name } = props;

    return (
        <MaskedInput
        name={name}
        value={value}
        mask="999.999.999-99"
        maskPlaceholder={"_"}
        alwaysShowMask
        onChange={e => {
            e.persist();
            onChange(e.target.value);
        }}
        >
        {Input}
        </MaskedInput>
    );
};

export const CepMaskedInput = props => {
    const { value, onChange, name } = props;
    const [alert, setAlert] = useState('');
    
    return (
        <div style={{
            display:"flex",
            flexDirection:'column',
            }}>
            <MaskedInput
            name={name}
            value={value}
            mask="99999-999"
            maskPlaceholder={"_"}
            alwaysShowMask
            onChange={async e => {
                e.persist();
                onChange(e.target.value);

                try{
                    const json = await axios.get(`https://viacep.com.br/ws/${limpaCampo(e.target.value)}/json`)
                    
                    if(!json.data.erro){
                        setAlert(json.data.logradouro + " - " + json.data.bairro +", "+ json.data.uf);
                    }else {
                        setAlert('');
                    }
                }
                catch{ }
            }}
            >
            {Input}
            </MaskedInput>
            <p style={{
                color:"#12E61D",
                margin: alert ? "0 0 5px 0" : "0" 
                }}>
                {alert}
            </p>
        </div>
    );
};

export const DateMaskedInput = props => {
    const { value, onChange, name } = props;
    return (
        <MaskedInput
        name={name}
        value={value}
        mask="99/99/9999"
        maskPlaceholder={"_"}
        alwaysShowMask
        onChange={e => {
            e.persist();
            onChange(e.target.value);
        }}
        >
        {Input}
        </MaskedInput>
    );
};

export const CellMaskedInput = props => {
    const { value, onChange, name } = props;
    return (
        <MaskedInput
        name={name}
        value={value}
        mask="(99) 99999-9999"
        maskPlaceholder={"_"}
        alwaysShowMask
        onChange={e => {
            e.persist();
            onChange(e.target.value);
            console.log(e.target.value)
            console.log(value)
        }}
        >
        {Input}
        </MaskedInput>
    );
};
