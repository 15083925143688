import React, { Component } from 'react'
import style from '../style/pages/institucional.module.css'
import Button from '../components/Button'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../util/util'

export class Institucional extends Component {
    state = {
        width: window.innerWidth
    }

    windowListener = () => {
        this.setState({
            width: window.innerWidth
        })
    }

    componentDidMount(){
        window.addEventListener('resize', this.windowListener)
        scrollToTop({ smooth: false });
    } 

    componentWillUnmount(){
        window.removeEventListener('resize', this.windowListener)
    }
    
    render() {
        const img = this.state.width > 1299 ?
        (<img alt="Network" width="600" src={"./network2_vertical.png"} style={{height:"100%"}}/>) 
        :(<img alt="Network" src={"./network2.png"} style={{width:"100%"}}/>);
        
        return (
            <div className={style.wrapper}>
                <div>
                    {img}
                </div>
                
                <div>
                    <div className={style.title}>
                        <h1>
                            Quem somos e o que{' '}
                            <span>nós</span>{' '}
                            fazemos
                        </h1>
    
                        <p>
                            A Solida Credi é uma Plataforma 
                            Multibanco digital. Nós te ajudamos
                            a encontrar o banco que aprovará o 
                            seu crédito com as melhores condições,
                            provendo uma assessoria completa desde 
                            a aprovação do seu crédito até a liberação 
                            do recurso. Tudo isso de forma gratuita, segura e 
                            online. Atendemos em todo o território nacional!
                        </p>
                        <Link to={'/imovel'}>
                            <Button 
                            contained={true}
                            text={'Simule Agora'}
                            type={'secondary'}
                            />
                        </Link>
                    </div>
                    
                    <div>
                        <h1>
                            O nosso trabalho é {' '}
                            <span>conectar você</span>{' '}
                            aos bancos e instituições financeiras
                        </h1>
                        <p>
                            Você terá acesso a um atendimento de qualidade
                            e personalizado. Faremos contato com você para 
                            entender suas necessidades, conhecer melhor o que 
                            você busca, condições do imóvel ou veículo a ser dado 
                            em garantia e assim  selecionar os bancos parceiros 
                            que sua proposta terá mais chance de se aprovada com 
                            excelentes condições. E isso não altera em nada a 
                            taxa de juros, fique tranquilo!
                        </p>
                    </div>
    
                    <div>
                        <h1>
                            Tudo isso de forma clara, objetiva e o melhor <span>sem custo nenhum para você</span>.
                        </h1>
                        <h2>
                            Aqui suas informações estão completamente seguras
                        </h2>
                        <p>
                            Suas informações só serão enviadas aos bancos e instituições
                            financeiras de sua escolha. Trabalhamos com sistemas seguros 
                            e confiáveis para envio de documentos aos bancos. 
                            As informações são sigilosas e serão utilizadas 
                            apenas para realização de análise de crédito, conforme regras 
                            estabelecidas pelo Banco Central.
                        </p>
                    </div>
                    <div>
                        <h3>
                            Solicite uma simulação sem compromisso e receba um contato de um de nossos consultores.
                        </h3>
                        <Link to={'/imovel'}>
                            <Button 
                            contained={true}
                            text={'Faça uma simulação'}
                            type={'primary'}
                            />
                        </Link>
                    </div>
                </div>
    
            </div>
        )
    }
}

export default Institucional
