import React from 'react'
import style from '../style/components/button.module.css'

function Button(props) {
    const classes = {
        default: style.default,
        primary: style.primary,
        secondary: style.secondary,
        tertiary: style.tertiary,
        quaternary: style.quaternary
    }

    const {
        text,
        type,
        contained,
        callback,
        submit,
        elem,
        children,
        round,
        bigger,
        fillWhenMobile,
        style: prevStyle,
        className: prevClass,
    } = props

    const btnStyle = classes[type];
    const containedClass = contained ? style.contained : '';
    const roundClass = round ? style.round : '';
    const submitForm = submit ? 'submit' : '';
    const biggerClass = bigger ? style.bigger : '';
    const fillWhenMobileClass = fillWhenMobile ? style.fill : '';
    const classNames = `${style.btn} ${btnStyle} ${roundClass} ${containedClass} ${prevClass} ${biggerClass} ${fillWhenMobileClass}`

    if (elem === "div") {
        return (
            <div
                onClick={callback}
                className={classNames}
                type={submitForm}
                style={prevStyle}
            >
                {text}
                {children}
            </div>
        )
    }
    else {
        return (
            <button
                onClick={callback}
                className={classNames}
                type={submitForm}
                style={prevStyle}
            >
                {text}
                {children}
            </button>
        )
    }
}

export default Button
