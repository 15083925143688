import React from 'react'
import style from '../style/components/footer.module.css'
import { Link } from "react-router-dom";
import { ImWhatsapp } from 'react-icons/im'
import { AiOutlinePhone } from 'react-icons/ai'

function Footer() {
    return (
        <div className={style.wrapper}>
            <div className={style.upperContainer}>
                <div>
                    <div className={style.empresa}>
                        <img src="./favicon.png" alt="Logo Solidá Credi" width="50" />

                        <div>
                            <h1>
                                Sólida Credi
                            </h1>
                            <h3>
                                Plataforma
                                Multibanco
                            </h3>
                        </div>
                    </div>
                    <p>
                        Rua Rio Grande do Norte,
                        1436 - 16° andar, Savassi –
                        Belo Horizonte MG – CEP:
                        30.130-138
                    </p>
                </div>
                <div className={style.contatos}>
                    <p>
                        E-mail
                    </p>
                    <h2 className={style.email}>
                        contato@solidacredi.com.br
                    </h2>
                    <p>
                        Telefone e Whatsapp
                    </p>
                    <h2>

                        <span><ImWhatsapp className={style.icon} /> +55 31</span> 99365 0000
                    </h2>
                    <p>Telefone fixo</p>
                    <h2>
                        <span><AiOutlinePhone className={style.icon} /> +55 31</span> 3046 8176
                    </h2>
                    <p>
                        Horário de
                        atendimento:
                        <br />
                        <br />
                        Seg a Sex: 8 às 19 hs <br />
                        Sáb de 9 às 12 hs
                    </p>
                </div>
                <div className={style.links}>
                    <Link className={style.link} to="/">Página Inicial</Link>
                    <Link className={style.link} to="/institucional">Quem somos</Link>
                    <Link className={style.link} to="/imovel">Empréstimo com garantia de imóvel</Link>
                    {/* <Link className={style.link} to="/veiculo">Empréstimo com garantia de veículo</Link>
                    <Link className={style.link} to="/empresa">Empréstimo com garantia para sua empresa</Link> */}
                </div>
            </div>
            <div className={style.middleContainer}>
                <p>
                    A Sólida Credi é formalmente representada pela empresa Solida Credi Soluções, inscrita no CNPJ 40.214.880/0001-13, com sede na Rua Rio Grande do Norte, 1436 - 16° andar, Savassi – Belo Horizonte MG – CEP: 30.130-138. Atua como correspondente de instituições financeiras que tem parceria. Todas as instituições parceiras são regidas pela Resolução nº. 3.954 do Banco Central e Resolução 4.935/21 do Conselho Monetário Nacional. A Sólida Credi é uma plataforma digital e o seu papel consiste em indicar/apresentar o cliente interessado pelo o empréstimo às instituições financeiras parceiras, assessorando o cliente na busca das melhores condições no mercado.
                </p>
                <p>
                    Como Promotores de correspondentes bancários temos a certificação profissional exigida, registrada na Central de Registros de Certificados Profissionais – CRCP – Crédito Imobiliário.
                </p>
                <p>
                    As taxas de juros, custo efetivo total (CET) e as condições ofertadas serão apresentadas de forma clara e transparente pela instituição financeira parceira que aprovar o crédito e podem variar de acordo com a política de crédito de cada instituição financeira parceira.
                    A taxa efetiva levará em conta os custos da operação de cada instituição.
                    Exemplo: Empréstimo com Garantia de Imóvel: mínimo 60 meses e máximo 240 meses. Empréstimo de R$ 100.000,00 para pagar em 20 anos (240 meses) – Prestação inicial de R$ 1.274,72, com uma taxa de juros de 0,99% ao mês + IPCA, Sistema de Amortização Price. CET de 12,55% ao ano.
                </p>
                <p>
                    <b>
                        Nosso serviço é totalmente gratuito ao cliente, portanto não o solicitaremos nenhum tipo de depósito ou pagamento.
                    </b>
                </p>
                <p>
                    Não enviamos emails com extensões públicas (@gmail, @yahoo, @hotmail etc.), apenas @solidacredi.com.br. Consulte o CET (Custo Efetivo Total) antes de contratar o seu empréstimo. Use o crédito de forma consciente.
                </p>
            </div>
            <div className={style.bottomContainer}>
                <p>
                    Solida credi ©2023
                </p>
                <div className={style.btnWrapper}>
                    <p>
                        termos de uso
                    </p>
                    <p>
                        política de privacidade
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer
