import footerStyles from '../style/components/footer.module.css'
import axios from 'axios';

export function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;

    if(strCPF === "00000000000") return false;

    for(let i = 1; i <=9;++i ){
        if(strCPF === "11111111111"*i)
            return false;
    }


    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10)) ) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}

export function scrollToTop(args){
    const { smooth } = args 

    if(smooth){
        window.scrollTo({top: 0, behavior: 'smooth' });
    }else{
        window.scrollTo({top: 0});
    }
}

export function scrollToBottom(){
    window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth' });
}

export function scrollToElem(elem){
    window.scrollTo({top: elem.offsetTop, behavior: 'smooth' });
}

export function scrollToFooter(){
    let footer = document.querySelector("."+footerStyles.wrapper)
    scrollToElem(footer);
}

export function jsonToFormData(json){
    let form = new FormData();

    Object.keys(json).forEach((key) => {
        form.append(key, json[key])
    })

    return form;
}

export async function sendFormData(data, table, isUpdate, forcePOST){
    let formEnvio = {...data};

    if(data?.DataNascimento){
        let dataAux = data.DataNascimento.split('/')
 
        let newData = '';
        newData += dataAux.pop()+"-";
        newData += dataAux.pop()+"-";
        newData += dataAux.pop();
        formEnvio.DataNascimento = newData;
    }

    if(forcePOST){
        try{
            const endereco = await axios.get(`https://viacep.com.br/ws/${limpaCampo(formEnvio.CEP)}/json`);
        
            formEnvio.Bairro = endereco.data.bairro;
            formEnvio.Municipio = endereco.data.localidade;
            formEnvio.Logradouro = endereco.data.logradouro;
            formEnvio.UF = endereco.data.uf;
        }catch{ }

        formEnvio.Incompleto = 0;
        
        const response = await fetch(`${window.location.origin}/api/${table}`,{
            method:"POST",
            body:jsonToFormData(formEnvio) 
        });
        
        const json = await response.json();
        
        return json.Id;
    }
    

    formEnvio.Incompleto = 1;
    if(!isUpdate){
        const response = await fetch(`${window.location.origin}/api/${table}`,{
            method:"POST",
            body:jsonToFormData(formEnvio) 
        });
        
        const json = await response.json();
        
        return json.Id;
    }else{
        const endereco = await axios.get(`https://viacep.com.br/ws/${limpaCampo(formEnvio.CEP)}/json`);
        
        formEnvio.Incompleto = 0;
        formEnvio.Bairro = endereco.data.bairro;
        formEnvio.Municipio = endereco.data.localidade;
        formEnvio.Logradouro = endereco.data.logradouro;
        formEnvio.UF = endereco.data.uf;
                    
        const json = await fetch(`${window.location.origin}/api/${table}`,{
            method:"PUT",
            body:jsonToFormData(formEnvio)
        });

        return await json.json();
    }
}

export function limpaCampo(campo){
    campo = campo.replace(/(\s+[.,-]*\s*)|([.,-]\s*)/g, '')
    while (campo.includes('_')) {
        campo = campo.replace('_', '');
    }
    return campo;
}