import React from 'react'
import style from '../style/components/graphicbanner.module.css'
import { Link } from 'react-router-dom'
import Button from './Button'

export function GraphicBanner(props) {
    return (
        <div className={style.container}>
            <div className={style.title}>
                <h1>
                    Veja e compare
                </h1>
                <h2>
                    As linhas de crédito mais baratas agora a sua mão
                </h2>
                <div className={style.line}></div>
            </div>
            <div className={style.text}>
                <p>
                    As linhas com garantia são mais
                    vantajosas. Enquanto alguns
                    empréstimos chegam até 12% de
                    juros ao mês, a taxa com garantia
                    é a partir de 0,90% ao mês.
                </p>
                <p>
                    Faça uma simulação e veja a diferença.
                </p>
                <Link to={'/imovel'}>
                    <Button
                        contained
                        round
                        text={'Simule Agora'}
                        type={'secondary'}
                    />
                </Link>
            </div>
            <div className={style.imgContainer}>
                <img
                    src="./graphic_transparent.png"
                    width="600px"
                    alt="Gráfico, até 10x mais barato"
                />
            </div>
        </div>
    )
}

export default GraphicBanner
