import React, { Component } from 'react'
import style from '../style/components/navmenu.module.css'
import NavButton from './NavButton'
import HamburguerMenu from './HamburgerMenu'

export class NavMenu extends Component {
    state = {
        width: window.innerWidth
    }

    navListener = () => {
        this.setState({
            width: window.innerWidth
        })
    }

    componentDidMount(){
        window.addEventListener('resize', this.navListener)
    } 

    componentWillUnmount(){
        window.removeEventListener('resize', this.navListener)
    }

    render() {
        const { showMenu }= this.props;

        const btns = this.props.btns.map((elem, id) => {
            if(elem.callback){
                return <NavButton 
                    key ={id} 
                    callback={elem.callback} 
                    text={elem.text}
                />
            }
            else{
                return <NavButton 
                    key ={id} 
                    link={elem.link} 
                    text={elem.text}
                />
            }
        })

        if(this.state.width > 1200)
            return (
                <div className={style.container}>
                    {btns}
                </div>
            )
        else
            return(
                <div className={style.containerMobile} onClick={showMenu}>
                    <HamburguerMenu />
                </div>
            )        
    }
}

export default NavMenu
