import React from 'react'
import { Link } from 'react-router-dom'
import style  from '../style/components/menuaccordion.module.css'

function MenuAccordion(props) {
    const {
        btns, show
    } = props

    const Btns = btns.map((elem, id) => {
        const lastClass = id+1 === btns.length? style.last: '';
        if(elem.callback){
            return(
                <div onClick={elem.callback} className={`${style.item} ${lastClass}`}>
                    <b>{elem.text}</b>
                </div>
            )
        }else{
            return (
                <Link to={elem.link} className={`${style.item} ${lastClass}`}>
                    <b>{elem.text}</b>
                </Link>
            )
        }
    })

    let showClass = ''

    if(show)
        showClass = style.show
    else
       showClass = ''
 
    return (
        <div className={`${style.wrapper} ${showClass}`}>
            {Btns}
        </div>
    )
}

export default MenuAccordion
