import React from 'react'
import style from '../style/pages/resultado.module.css'

function resultado() {
    /*
        cgi - cgv - cge 
    */
    const form = window.location.pathname.substr(-3);
    
    const forms = {
        cgi: "crédito em garantia com imóvel",
        cgv: "crédito em garantia com veículo",
        cge: "crédito em garantia para sua empresa",
    }
    return (
        
        <div className={style.wrapper} style={{
            backgroundImage: "url(\"bg_realizado.jpg\")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        }}>
            <h1>
                Obrigado por confiar na <span>Sólida Credi</span>{' '}
                para encontrar as melhores propostas para <span>você!</span>
            </h1>
            <h2>
                Seu pedido de simulação de {forms[form]} foi solicitado com sucesso!
            </h2>
            <p>
                Nossos analistas irão entrar em contato com você em breve.
            </p>
        </div>
    )
}

export default resultado
