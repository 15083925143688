import Home from './pages/Home'
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Imovel from './pages/Imovel'
import Resultado from './pages/resultado'
import Institucional from './pages/Institucional'
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/institucional" component={Institucional} />
        <Route path="/imovel" component={Imovel} />
        <Route path="/resultado-cgi" component={Resultado} />
      </Switch>
      <Footer />
    </Router>
   );
}

export default App;
