import React, { Component } from 'react'
import style  from '../style/components/header.module.css'
import NavMenu from './NavMenu'
import MenuAccordion from './MenuAccordion'
import { Link } from 'react-router-dom'
import { scrollToFooter } from '../util/util'
const buttons = [
    {
        link: "",
        text: "Início",
    },
    {
        link: "institucional",
        text: "Quem somos",
    },
    {
        link: "imovel",
        text: "Crédito com Imóvel em Garantia",
    },
    // {
    //     link: "veiculo",
    //     text: "Crédito com Veículo em Garantia",
    // },
    // {
    //     link: "empresa",
    //     text: "Crédito para sua Empresa",
    // },
    {
        callback: scrollToFooter,
        text: "Contato",
    },
];

export class Header extends Component {
    state = {
        showAccordion: false
    }

    toggleMenuAccordion = ()=> {
        this.setState({
            showAccordion: !this.state.showAccordion
        })
    }

    render() {
        return (
            <div className={style.wrapper}>
                <div className={style.container}>
                    <div className={style.logo}>
                        <Link to="/" >
                            <img alt="Sólida Cred Logo" height="50px" src="./logo.svg" />
                        </Link>
                    </div>
    
                    <NavMenu btns={buttons} showMenu={this.toggleMenuAccordion} />
                </div>

                <MenuAccordion show={this.state.showAccordion} btns={buttons} />
            </div>
        )
    }
}

export default Header
