import React from 'react'
import style from '../style/pages/simularimovel.module.css'
import ImovelForm from '../components/ImovelForm'
import { FaCalendarAlt, FaHome, FaPercent } from 'react-icons/fa'
import { IconBanner } from '../components/IconBanner'
import { GrandIconBanner } from '../components/GrandIconBanner'
import { scrollToTop } from '../util/util'

function Imovel() {
    scrollToTop({ smooth: false });

    return (
        <div className={style.wrapper} >
            <div className={style.containerForm} style={{
                backgroundImage: "url(\"background.jpg\")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}>
                <div className={style.infoContainer}>

                    <h1>Empréstimo com Garantia de Imóvel</h1>
                    <h2>
                        Usando seu imóvel como garantia você consegue as <span>melhores</span> condições do mercado
                    </h2>
                    <h2>
                        De forma <span>rápida, gratuita e totalmente segura.</span>
                    </h2>

                    <h2>
                        Faça uma <span>simulação</span> agora mesmo!

                    </h2>

                    <div className={style.iconContainer}>
                        <FaCalendarAlt />
                        <FaPercent />
                        <FaHome />

                        <p>
                            Prazo até 240x para pagar
                        </p>
                        <p>
                            Taxas a partir de 0,90%
                        </p>
                        <p>
                            Libera até 60% do valor do seu imóvel
                        </p>

                    </div>
                </div>
                <ImovelForm className={style.form} />
            </div>
            <IconBanner />
            <GrandIconBanner />
        </div>
    )
}

export default Imovel

