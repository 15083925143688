import React, { Component } from 'react'
import style from '../style/components/stepbanner.module.css'
import Button from "./Button";
import { Link } from "react-router-dom";

export class StepBanner extends Component {
    render() {
        // steps [{title, description}]
        // link string
        const {
            steps,
            calltoaction
        } = this.props;

        let CTA;

        if (typeof calltoaction == 'string') {
            CTA = (
                <Link to={calltoaction}>
                    <Button
                        type="primary"
                        contained
                        style={{
                            marginTop: 25,
                        }}
                    >
                        Faça uma Simulação
                    </Button>
                </Link>
            )
        } else if (typeof calltoaction == 'function') {
            CTA = (
                <Button
                    type="primary"
                    contained
                    style={{
                        marginTop: 25,
                    }}
                    callback={calltoaction}
                >
                    Faça uma Simulação
                </Button>
            )
        }

        const stepsComponent = steps.map((elem, index) => (
            <div key={index} className={style.stepGrid}>
                <div className={style.number}>
                    <b className={style.num}>{index + 1}°</b>{' '}
                </div>

                <h1 className={style.title}>
                    {elem.title}
                </h1>

                <div className={style.description}>
                    {elem.description}
                </div>
            </div>
        ))

        const qtdSteps = steps.length;

        return (
            <div className={style.wrapper}>
                <div className={style.container}>
                    <div className={style.title}>
                        <h1>Veja como é fácil contratar!</h1>
                        <h2>Processo simples e análise flexível</h2>
                        <div className={style.line}></div>
                    </div>

                    <div className={style.stepsWrapper}>
                        <div className={`${style.stepsContainer} ${style['g' + qtdSteps]}`}>
                            {stepsComponent}
                        </div>
                    </div>

                    <div className={style.footer}>
                        <h3>
                            A Sólida Credi te acompanha em todas as etapas!
                        </h3>
                        <h4>
                            Assessoria gratuita, não solicitaremos nenhum tipo de depósito ou pagamento.
                        </h4>
                        <p>
                            Acessando a Plataforma da Sólida Credi, você será conectado de forma digital, rápida e segura às melhores taxas do mercado
                        </p>
                        {CTA}
                    </div>
                </div>
            </div>
        )
    }
}

export default StepBanner
