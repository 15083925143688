import React, { Component } from 'react'
import style from '../style/components/banner.module.css'
import PromotionImovel from './PromotionImovel'


export class Banner extends Component {
    state = {
        width: 0
    }

    bannerListener = () => {
        this.setState({ width: window.innerWidth })
    }

    componentDidMount() {
        window.addEventListener('resize', this.bannerListener)
        this.bannerListener();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.bannerListener)
    }

    render() {
        const { subtitle } = this.props
        let containerStyle = {}

        if (this.state.width > 980) {
            containerStyle = {
                backgroundImage: "url(\"banner_crop.png\")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            }
        } else {
            containerStyle = {}
        }

        return (
            <div
                className={style.container}
                style={containerStyle}
            >
                <div className={style.text_container}>
                    <h1 className={style.title}>
                        Empréstimo com <br/>
                        <span>garantia de imóvel</span>
                    </h1>
                    <h2 className={style.subtitle}>
                        {subtitle}
                    </h2>
                    <p>
                        Use seu imóvel como <b>garantia</b> e tenha acesso a crédito com muito <b>menos juros</b> e mais tempo para pagar
                    </p>
                    <p>
                        A Solida Credi é uma <b>Plataforma Digital Multibanco</b>. Com um único cadastro enviamos sua solicitação para vários Bancos e Instituições financeiras buscando aprovar o seu crédito com a menor taxa.
                    </p>
                </div>

                <PromotionImovel />
            </div>
        )


    }
}

export default Banner