import React from 'react'
import Banner from '../components/Banner'
import StepBanner from '../components/StepBanner'
import IconBanner from '../components/IconBanner'
import GraphicBanner from "../components/GraphicBanner";
import { scrollToTop } from '../util/util'
import GrandIconBanner from '../components/GrandIconBanner'

function Home() {
    scrollToTop({ smooth: false });

    const steps = [
        {
            title: "Preencha seus dados",
            description: 'Simule, sem compromisso, para conhecer o valor da parcela mensal. Não perca tempo indo de banco em banco.',
        },
        {
            title: 'Analisamos o seu Perfil',
            description: 'Um consultor entrará em contato com você para entender seus objetivos, sua renda e o perfil do imóvel que entrará em garantia.',
        },
        {
            title: 'Crédito Aprovado',
            description: 'Você avalia as Propostas aprovadas de cada banco e dá seu aceite para o Banco que apresentou as melhores condições.',
        },
        {
            title: 'Receba seu Crédito',
            description: 'Banco emite o seu contrato, registra em cartório a alienação do imóvel e libera o seu dinheiro na sua conta.',
        }
    ]

    return (
        <div>
            <Banner
                subtitle='A linha de crédito com a menor taxa de juros do mercado'
                imgSrc='./banner.png'
            />
            <GraphicBanner />
            <StepBanner steps={steps} calltoaction={"/imovel"} />
            <IconBanner style={{ padding: "80px 80px 80px 80px" }} />
            <GrandIconBanner />
        </div>
    )
}

export default Home
