import React from 'react'
import style from '../style/components/grandiconbanner.module.css'
import {
    FaBalanceScale,
    FaMoneyBillWave,
    GiSuitcase,
    GiPayMoney,
    GiFamilyHouse,
    IoBusiness,
    GiCommercialAirplane,
    TbCalendarStats
} from 'react-icons/all'

export function GrandIconBanner(props) {
    return (
        <div className={style.wrapper}>
            <div className={style.textContainer}>
                <h1>
                    O <span>Crédito Com Garantia de Imóvel</span> pode ajudar você a concretizar sonhos e organizar suas finanças, use o recurso como quiser!
                </h1>
            </div>
            <div className={style.iconContainer}>
                <div>
                    <div className={style.icon}>
                        <GiPayMoney />
                    </div>
                    <p>Trocar dívidas caras por uma única e mais barata</p>
                </div>
                <div>
                    <div className={style.icon}>
                        <GiFamilyHouse />
                    </div>
                    <p>Reforma ou Construção do seu imóvel</p>
                </div>
                <div>
                    <div className={style.icon}>
                        <IoBusiness />
                    </div>
                    <p>Investimento na sua empresa</p>
                </div>
                <div>
                    <div className={style.icon}>
                        <GiSuitcase />
                    </div>
                    <p>Abrir seu negócio próprio/franquias</p>
                </div>
                <div>
                    <div className={style.icon}>
                        <FaMoneyBillWave />
                    </div>
                    <p>Capital de giro para sua empresa</p>
                </div>
                <div>
                    <div className={style.icon}>
                        <FaBalanceScale />
                    </div>
                    <p>Reorganizar suas finanças</p>
                </div>
                <div>
                    <div className={style.icon}>
                        <GiCommercialAirplane />
                    </div>
                    <p>Fazer uma viagem</p>
                </div>
                <div>
                    <div className={style.icon}>
                        <TbCalendarStats />
                    </div>
                    <p>Antecipar valor de um imóvel à venda</p>
                </div>
            </div>
        </div>
    )
}

export default GrandIconBanner
